@import 'react-credit-cards/lib/styles.scss';

.wrapper {
    margin: 20px;
}

.ant-form, .detail-wrapper {
    margin-top: 20px !important;
}
.rccs {
    margin-bottom: 20px;
}

.detail-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}