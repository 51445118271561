.appHeader {
    background-color: #ff8ac9;
    padding: 10px 0;
    display: flex;
    align-items: center;

    .title {
        padding: 5px;
        color: white;
    }
}

.App-logo{
    max-width: 200px;
    max-height: 70px;
    padding: 0 2.5%;
}

@media only screen and (max-width: 600px) {
    .App-logo {
        max-width: 100px;
        max-height: 50px;
    }
    h1 {
        font-size: 1em;
    }
}