.table-container{
    padding: 0 5%;
    max-width: 100%;

    .user-data{
        padding: 10px 0;

        .label{
            padding: 0 10px 0 0;
        }
    }

    .wrapper-data {
        display: flex;
        justify-content: space-between;
    }
}

.row-wrapper {
    background-color: white;
    .ant-col {
        padding: 10px 0;
    }
    .ant-affix {
        background-color: white;
    }
}

.expired {
    background-color: #ff020242;
}