.login-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bigBox {
    display: flex;
    margin: auto;
    height: 100%;
    position: relative;

    @media screen and (max-width: 500px) {
        flex-wrap: wrap-reverse;

        .content {
            width: 100% !important;
            height: initial !important;
            margin: auto;

            .login-img {
                height: 40vh !important;
            }

            .App-logo {
                width: 50% !important;
            }
        }

        .content.form {
            padding: 15% 8% 0 !important;
            .login-label {
                padding: 4.5% 0 0 !important;
            }
        }
    }

    @media screen and (max-width: 350px) {
        flex-wrap: wrap-reverse;

        .content {
            width: 100% !important;
            height: initial !important;
            margin: auto;

            .login-img {
                height: 40vh !important;
            }

            .App-logo {
                width: 50% !important;
            }
        }

        .content.form {
            padding: 15% 8% 0 !important;
            .login-label {
                padding: 1% 0 0 !important;
            }
            .ant-form-item{
                margin-bottom: 12px !important;
            }
        }
    }

    .content {
        width: 50%;
        margin: auto;
        height: 100%;

        .login-img {
            width: 100%;
            height: 100vh;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            object-fit: cover;
        }

        .App-logo {
            width: inherit;
        }
    }

    .content.form {
        padding: 15% 8%;
        background-color: white;
        margin: auto;  
        
        .login-label {
            padding: 10% 0;
        }

        .ant-form-item{
            div {
                width: 100%;
                text-align: left;
            }
        }

        input{
            width: 100%;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid;
        }
    }
}

.buttons {
    width: 100%;
}